<template>
  <b-row>
    <b-col cols="12">
      <candidate-add-form />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import CandidateAddForm from './CandidateAddForm.vue'

export default {
  components: {
    BRow,
    BCol,
    CandidateAddForm,
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-wizard.scss';
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
